export const environment = {
  production: true,
  environmentName: 'Pre-Prod',
  apiUrl: 'https://osh-preprod-api.oneapihub.com',
  apiBasePath: 'api/v1/',
  countryApiUrl: 'https://ipinfo.io/json?token=d102c1284570ee',
  speedPostEndpoint: 'https://speedpost-preprod.digitalapicraft.com',
  firebaseConfig: {
    apiKey: 'AIzaSyBVXPXRMEK_RQ304wEb_gB8Xc7TUZNk6Ik',
    authDomain: 'apimanager-12.firebaseapp.com',
    projectId: 'apimanager-12',
    storageBucket: 'apimanager-12.appspot.com',
    messagingSenderId: '1019068528267',
    appId: '1:1019068528267:web:8d6fcd35100fb5028c468e',
    measurementId: 'G-QR1647819X',
  },
  analyticsTagUrl:
    'https://dac-analytics-tag-preprod-wzc46kdbjq-ez.a.run.app/dac-analytics-tag/api/v1/dac-analytics-tag.js',
  redirectUrl: 'https://osh-preprod.oneapihub.com',
  chatBotConfig: {
    scriptUrl:
      'https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js',
    styleUrl:
      'https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/themes/df-messenger-default.css',
    location: 'europe-west1',
    projectId: 'apimanager-12',
    agentId: '87fb6652-ed56-40cb-9ea4-1b04c46d1633',
    languageCode: 'en',
    title: 'OSH Support',
  },
  dapperURL: 'https://storage.googleapis.com/dapper-v2/dapperv2.standalone.js',
};
