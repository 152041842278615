import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { UserInfo } from '@angular/fire/auth';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AUTH_CONST } from '@app/core/constants/message.constant';
import navItems from '@app/core/constants/nav-items';
import { AuthService } from '@app/core/services/auth/auth.service';
import { FeatureManagementService } from '@app/core/services/feature-management/feature-management.service';
import { SettingsService } from '@app/core/services/settings/settings.service';
import { UiCustomisationService } from '@app/core/services/settings/ui-customisation/ui-customisation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserDetailService } from '@app/core/services/user-detail/user-detail.service';
import { cloneDeep } from '@app/core/utils/clone-deep';
import { RolesEnum } from '@app/shared/enum/role-enum';
import {
  CreditsModel,
  OrganisationCompactModel,
  PlansModel,
  UserCompactModel,
} from '@app/shared/models/manage-user/user-compact-detail.model';
import { NavbarModel } from '@app/shared/models/other/navbar.models';
import { UiCustomiseActiveEnum } from '@app/shared/models/ui-customisation/ui-customisation.model';
import { environment } from '@environments/environment';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { Observable } from 'rxjs';
import { ChatBotComponent } from '../../chat-bot/chat-bot.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    CollapseModule,
    BsDropdownModule,
    ProgressbarModule,
    ChatBotComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isCollapsed = false;
  userData = null as UserInfo | null;
  userHighestRole = null as RolesEnum | null;
  isLoggedIn = false;
  isAdmin = false;
  userCompactDetails = null as UserCompactModel | null;
  userPlanDetails$: Observable<PlansModel | null>;
  navList = navItems as NavbarModel[] | [];
  backUpNavList = cloneDeep(navItems) as NavbarModel[] | [];
  currentNavList = this.navList[0] as NavbarModel | null;
  defaultRoute = '';
  discoverApisNavPath = 'external';
  userOrgData: OrganisationCompactModel | null = null;
  userRoles: RolesEnum[] = [];
  version: string | undefined;
  logoImage: string | null = null;
  // chatBotConfig: ChatBotConfigModel = environment.chatBotConfig;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userDetailService: UserDetailService,
    private settingsService: SettingsService,
    private uiCustomisationService: UiCustomisationService,
    private featuresService: FeatureManagementService,
    private toastService: ToastService,
  ) {
    if (this.authService?.userData) {
      this.authService?.userData.subscribe((user) => {
        this.isLoggedIn = !user;
        this.userData = user ?? null;
      });
    }
    this.userDetailService.userCompactDetail$.subscribe((value) => {
      this.userCompactDetails = value;
      this.userHighestRole = this.userDetailService.currentUserHighestRole;
      this.userRoles = this.userDetailService.currentUserRoles;

      if (this.userDetailService.isAdminOrSuperAdmin) {
        this.isAdmin = true;
      }

      this.userOrgData = value && value['userOrganisation'];
      this.getSettingsForOrganisation();
      this.showNavItemsBasedOnPermissions();
    });

    this.uiCustomisationService.localUiCustomiseData$.subscribe(() => {
      const customData = cloneDeep(
        this.uiCustomisationService.customUiCustomiseData.getValue(),
      );
      this.logoImage =
        this.uiCustomisationService.activeUiCustomisation ===
          UiCustomiseActiveEnum.CUSTOM && customData?.logoImage?.fileContent
          ? customData?.logoImage.fileContent
          : null;
    });
    this.userPlanDetails$ = this.userDetailService.userPlanDetail$;
    this.routeChangeEvent();
  }

  ngOnInit() {
    this.getUserDetails();
    this.getVersion();
    this.onResize(null);
    this.showNavItemsBasedOnPermissions();
  }

  get getEnvironmentName(): string {
    return environment.environmentName;
  }

  routeChangeEvent() {
    if (typeof window !== 'undefined')
      this.setCurrentNavList(window.location.pathname);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    this.router.events.subscribe((event: Event | any) => {
      if (
        event instanceof NavigationEnd ||
        event.routerEvent instanceof NavigationEnd
      ) {
        const cEvent =
          event?.routerEvent instanceof NavigationEnd
            ? event.routerEvent
            : event;
        const matchUrl =
          cEvent.url === '/home' ? cEvent.url : cEvent.url.replace('/home', '');
        this.defaultRoute = cEvent.url === '/home' ? '' : '/home/';
        const elem = this.navList.find((x) => matchUrl.includes(x.path));
        if (elem) {
          this.currentNavList = elem;
        } else {
          this.currentNavList = this.navList[0];
        }
      }
    });
  }

  setCurrentNavList(matchUrl: string) {
    const elem = this.navList.find((x) => matchUrl.includes(x.path));
    if (elem) {
      this.currentNavList = elem;
    } else {
      this.currentNavList = this.navList[0];
    }
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onResize(event: any) {
    const innerWidth = event ? event?.target?.innerWidth : window?.innerWidth;
    this.isCollapsed = innerWidth > 768 ? false : true;
  }

  logout() {
    this.settingsService.orgSettingsValue.next(null);
    this.settingsService.orgSettings = null;
    this.isCollapsed = !this.isCollapsed;
    this.authService.signOut();
  }

  getUserDetails() {
    const payload = {
      email: this.authService.getEmailInLocalStorage,
      firstLoginCompleted: false,
    };
    if (this.authService.getEmailInLocalStorage.length) {
      this.userDetailService.getUserDetails(payload).subscribe({
        next: (res) => {
          if (res.status_code === 200) {
            this.userDetailService.emitCompactDetail(res.data);
          } else {
            this.logout();
            this.toastService.error(res.message);
          }
        },
        error: (err) => {
          this.logout();
          this.toastService.error(err.error ? err.error?.error : err.message);
        },
      });
    } else {
      this.logout();
      this.toastService.error(AUTH_CONST.SESSION_EXPIRED);
    }
  }

  getProgressVal(credit: CreditsModel) {
    return credit?.remainingCredit?.remainingCredits
      ? ((credit?.remainingCredit?.remainingCredits ?? 1) / credit.quantity) *
          100
      : 0;
  }

  customRouteActive(path: string): boolean {
    return this.router.url.includes(path);
  }

  getRouterPath(nav: NavbarModel): string {
    if (
      nav.path === 'administration' ||
      nav.path === 'digital-contents' ||
      nav.path === 'settings'
    ) {
      const activeItemIndex = this.findFirstActiveItemIndex(nav);
      return (
        nav.path +
        '/' +
        nav.items[activeItemIndex == -1 ? 0 : activeItemIndex].path
      );
    } else if (nav.path === 'discover-apis') {
      return nav.path + '/' + this.discoverApisNavPath;
    }
    return nav.path;
  }

  findFirstActiveItemIndex(nav: NavbarModel): number {
    return nav.items.findIndex((item) =>
      this.filterInternalNavOnPermission(item.features, item.permissions),
    );
  }

  getSettingsForOrganisation() {
    this.settingsService.getOrganisationSettings().subscribe((value) => {
      if (value) {
        const enabledPortals = value.portalsSettings.filter((x) => x.enabled);
        if (this.userHighestRole && enabledPortals.length > 0) {
          this.discoverApisNavPath = enabledPortals[0].portalType.toLowerCase();
        } else if (!this.userHighestRole) {
          const displayPortal = enabledPortals.find(
            (x) => x.accessRole === this.userRoles[0],
          );
          if (displayPortal?.portalType)
            this.discoverApisNavPath = displayPortal?.portalType.toLowerCase();
        }
      }
    });
  }

  getVersion() {
    this.settingsService.getVersion().subscribe({
      next: (res) => {
        if (res?.status_code === 200) {
          this.version = res.data?.toString();
        }
      },
    });
  }

  showNavItemsBasedOnPermissions() {
    // const updatedNavList = this.backUpNavList.filter((nav) => {
    //   return nav.features && nav.features.length > 0
    //     ? this.featuresService.showHideFeaturesBasedOnAvailability(nav.features)
    //     : true;
    // });
    const updatedNavList = this.backUpNavList.filter((nav) => {
      return this.featuresService.validateFeaturesAndPermissions(
        nav.features,
        nav.permissions,
      );
    });
    this.navList = cloneDeep(updatedNavList);
  }

  filterInternalNavOnPermission(features: string[], permissions: string[]) {
    // return featureName && featureName.length > 0
    //   ? this.featuresService.showHideFeaturesBasedOnAvailability(featureName)
    //   : true;
    return this.featuresService.validateFeaturesAndPermissions(
      features,
      permissions,
    );
  }
}
