import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppBrowserModule } from '@app/app.browser.module';
import { SSR_ENV_VARIABLES_CONST } from '@app/core/constants/api.constant';
import { environment } from '@environments/environment';

(async () => {
  const response = await fetch('/' + SSR_ENV_VARIABLES_CONST, {
    method: 'GET',
    headers: { 'skip-api-base-path': 'true' },
  });
  const config = await response.json();
  environment['analyticsTagUrl'] = config.analyticsTagUrl;
  environment['countryApiUrl'] = config.countryApiUrl;
  environment['firebaseConfig'] = config.firebaseConfig;
  environment['chatBotConfig'] = config.chatBotConfig;
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => console.error(err));
})();
