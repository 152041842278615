import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() size: '' | 'sm' | 'lg' | 'xlg' = '';
  @Input() isAI = false as boolean;
  @Input() color: '' | 'primary' | 'secondary' | 'light' | 'white' = '';
}
