<div class="d-flex justify-content-center">
  <span
    *ngIf="!isAI"
    class="spinner-border"
    [ngClass]="{
      'spinner-border-lg': size === 'lg',
      'spinner-border-sm': size === 'sm',
      'spinner-border-xlg': size === 'xlg',
      'spinner__color--default': !color,
      text__primary: color === 'primary',
      'text-secondary': color === 'secondary',
      'text-light': color === 'light',
      'text-white': color === 'white'
    }"
    role="status"
    aria-hidden="true"
  ></span>
  <img
    *ngIf="isAI"
    src="/assets/videos/ai-spinner.gif"
    class=""
    alt="abc"
    type="image/gif"
  />
</div>
